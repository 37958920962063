import { Checkbox, DatePicker, Input, Row, Select, TimePicker } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { IRow } from '../../app/AppInterfaces';
import { ComboData } from '../../combos/ComboInterfaces';
import { isConPlatform, loadTimezoneName } from '../../utils';
import { ResourceTypeDetailEnum } from '../Enums';
import { getBooleanValue } from './Resource.utils';
import { QUIZ_TYPEFORM_DESCRIPTION_LIMIT } from './Resource';
import MarkdownInput from './Markdown/Markdown';
import _moment from 'moment';

interface ComponentProps {
  combo?: ComboData[];
  disabled?: boolean;
  editResource: boolean;
  form?: WrappedFormUtils<any>;
  idResourceD: number;
  idResourceTypeD?: number;
  handleUpdateDetail: (idResourceD: number, value: any) => void;
  title?: string;
  value: any;
  values?: IRow;
}

export const TextArea = ({
  editResource,
  idResourceD,
  idResourceTypeD,
  handleUpdateDetail,
  value,
}: ComponentProps) => {
  let maxLength: number | undefined;

  let inputComponent;

  switch (idResourceTypeD) {
    case ResourceTypeDetailEnum.MATERIAL_DESCRIPTION:
      maxLength = 160;
      break;
    case ResourceTypeDetailEnum.RESOURCE_GUIDE_DESCRIPTION:
      maxLength = 400;
      break;
    case ResourceTypeDetailEnum.TEXT_BODY:
      inputComponent = (
        <MarkdownInput
          value={value}
          disabled={!editResource}
          onChange={(value?: string) => handleUpdateDetail(idResourceD, value)}
        />
      );
      break;
    default:
      maxLength = 250;
  }

  if (isConPlatform())
    maxLength =
      idResourceTypeD === ResourceTypeDetailEnum.QUIZ_TYPEFORM_DESCRIPTION
        ? QUIZ_TYPEFORM_DESCRIPTION_LIMIT
        : undefined;

  return (
    inputComponent || (
      <Input.TextArea
        maxLength={maxLength}
        disabled={!editResource}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          handleUpdateDetail(idResourceD, e.target.value)
        }
      />
    )
  );
};

export const TimeField = ({
  editResource,
  idResourceD,
  handleUpdateDetail,
}: ComponentProps) => {
  return (
    <TimePicker
      disabled={!editResource}
      onChange={(time: moment.Moment, timeString: string) =>
        handleUpdateDetail(idResourceD, timeString)
      }
    />
  );
};

export const DateField = ({
  disabled = false,
  idResourceD,
  handleUpdateDetail,
  value,
  customDateToStringFn,
}: ComponentProps & { customDateToStringFn?: (date: Date) => string }) => {
  // The initial value of the DatePicker is set in ResourceDetails.utils (see 'case Date: ')
  // Attribute 'value' is useless and can be removed due the initial value of the DatePicker is..
  // ..set through the 'GetFieldDecorator' (see Antd docs for more info)

  // Need to set DatePicker timezone as well to avoid the component to use local machine timezone
  const currentTimezone = loadTimezoneName();
  _moment.tz.setDefault(currentTimezone);
  return (
    <DatePicker
      format="DD-MM-YYYY HH:mm"
      value={value}
      disabled={disabled}
      showTime={{ format: 'HH:mm' }}
      showToday={false}
      onChange={(date: any | null, dateString: string) => {
        let newDate = '';

        if (date) {
          newDate = date.clone().format('YYYY-MM-DD HH:mm:ss');

          const formattedDate = new Date(date);
          formattedDate.setUTCSeconds(0);

          newDate = customDateToStringFn
            ? customDateToStringFn(formattedDate)
            : formattedDate.toUTCString();
        }

        handleUpdateDetail(idResourceD, newDate);
      }}
    />
  );
};

export const CheckboxField = ({
  editResource,
  idResourceD,
  handleUpdateDetail,
  title,
  value,
}: ComponentProps) => {
  return (
    <Row className="BasicResource__checkbox">
      <Checkbox
        className="BasicResource__checkbox--input"
        checked={getBooleanValue(value)}
        disabled={!editResource}
        onChange={(e: CheckboxChangeEvent) =>
          handleUpdateDetail(idResourceD, e.target.checked)
        }
      />
      {title}
    </Row>
  );
};

export const ComboField = ({
  combo,
  editResource,
  idResourceD,
  handleUpdateDetail,
  value,
}: ComponentProps) => {
  let selectOptions: {
    disabled: boolean;
    onChange: any;
    value?: string;
    showSearch: boolean;
  } = {
    disabled: !editResource,
    onChange: (value: string) => handleUpdateDetail(idResourceD, value),
    value: value?.toString(),
    showSearch: true,
  };

  return (
    <Select {...selectOptions}>
      {combo?.map((option) => (
        <Select.Option
          key={option.value.toString()}
          value={option.value.toString()}>
          {option.description ?? option.label}
        </Select.Option>
      ))}
    </Select>
  );
};
