import { Button, Dropdown, Menu } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import XLSX from 'xlsx';
import * as api from '../api';
import { IRow } from '../app/AppInterfaces';
import { appComponents } from '../components';
import config from '../config';
import { ReducersState } from '../reducers';
import store from '../store';
import { Column } from '../tables/tableInterfaces';
import { feedback } from '../utils/feedback';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { isPINPlatform } from '../utils';

export const dataType = {
  CSV: {
    format: 'csv',
    type: 'text/csv;charset=utf-8',
  },
  XLS: {
    format: 'xls',
    type: 'data:application/vnd.ms-excel;charset=UTF-8',
  },
};

interface OwnProps {
  componentId: string;
  disabled: boolean;
  data: IRow[];
  totalElements: number;
  selectedRowKeys: number[];
  rowKey: string;
  multiple: boolean;
  columns: Column[];
  combos: ReducersState['combos'];
  intl: any;
}

interface OwnState {
  exportLoading: boolean;
}

class DataExport extends React.PureComponent<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      exportLoading: false,
    };
  }

  dataToXLS = (data: IRow[], columns: Column[]): [IRow[], any] | null => {
    if (isEmpty(data)) {
      return null;
    }
    let result: IRow[] = [];
    let headers: IRow = {};

    // HEADER
    columns
      .filter(({ mustRender }) => mustRender)
      .sort((prev, next) => {
        return prev.position - next.position;
      })
      .forEach(({ key, title }) => {
        headers[key] = title;
      });

    // BODY
    const { combos, intl, componentId } = this.props;

    data.forEach((item, i) => {
      let newValue: IRow = {};
      columns
        .filter(({ mustRender }) => mustRender)
        .sort((prev, next) => {
          return prev.position - next.position;
        })
        .forEach((column) => {
          const _columnTitle = headers[column.key];

          if (column && column.key && item) {
            const nestedObjArray = column.key.toString().split('.');
            if (
              nestedObjArray.length > 2 &&
              item[nestedObjArray?.[0]]?.[nestedObjArray[1]]?.[
                nestedObjArray[2]
              ]
            ) {
              if (
                column.render === 'combo' &&
                column.comboId &&
                combos.combos[componentId]
              ) {
                const { key, comboId } = column;
                if (
                  combos.combos[componentId]?.[key]?.[comboId]?.data?.length
                ) {
                  combos.combos[componentId][key][comboId].data.forEach(
                    ({ description, value }) => {
                      if (
                        value.toString() ===
                        item[nestedObjArray?.[0]][nestedObjArray[1]][
                          nestedObjArray[2]
                        ].toString()
                      )
                        newValue[_columnTitle] = description;
                    },
                  );
                }
              } else {
                let field =
                  item[nestedObjArray[0]][nestedObjArray[1]][
                    nestedObjArray[2]
                  ].toString();
                newValue[_columnTitle] = field;
              }
            } else if (
              nestedObjArray.length > 1 &&
              item[nestedObjArray[0]]?.[nestedObjArray[1]]
            ) {
              if (
                column.render === 'comboCustom' &&
                column.comboId &&
                combos.combosCustom[column.comboId]?.data
              ) {
                combos.combosCustom[column.comboId].data.forEach(
                  ({ description, value }) => {
                    if (
                      value ===
                      item[nestedObjArray[0]][nestedObjArray[1]].toString()
                    ) {
                      newValue[_columnTitle] = description;
                    }
                  },
                );
              } else if (
                column.render === 'combo' &&
                column.comboId &&
                combos.combos[componentId]
              ) {
                const { key, comboId } = column;
                if (
                  combos.combos[componentId]?.[key]?.[comboId]?.data?.length
                ) {
                  combos.combos[componentId][key][comboId].data.forEach(
                    ({ description, value }) => {
                      if (
                        value.toString() ===
                        item[nestedObjArray[0]][nestedObjArray[1]].toString()
                      ) {
                        newValue[_columnTitle] = description;
                      }
                    },
                  );
                }
              } else {
                let field =
                  item[nestedObjArray[0]][nestedObjArray[1]].toString();
                newValue[_columnTitle] = field;
              }
            } else if (
              nestedObjArray.length > 1 &&
              item[nestedObjArray[0]]?.[0]
            ) {
              if (
                column.render === 'comboCustom' &&
                column.comboId &&
                combos.combosCustom[column.comboId]?.data
              ) {
                combos.combosCustom[column.comboId].data.forEach(
                  ({ description, value }) => {
                    if (
                      value ===
                      item[nestedObjArray[0]][nestedObjArray[1]].toString()
                    ) {
                      newValue[_columnTitle] = description;
                    }
                  },
                );
              } else if (
                column.render === 'combo' &&
                column.comboId &&
                combos.combos[componentId]
              ) {
                const { key, comboId } = column;
                if (
                  combos.combos[componentId]?.[key]?.[comboId]?.data?.length
                ) {
                  combos.combos[componentId][key][comboId].data.forEach(
                    ({ description, value }) => {
                      if (
                        value.toString() ===
                        item[nestedObjArray[0]][0]['statusResource']?.toString()
                      ) {
                        newValue[_columnTitle] = description;
                      }
                    },
                  );
                }
              } else {
                let field =
                  item[nestedObjArray[0]][0]['createDate']?.toString();
                newValue[_columnTitle] = field;
              }
            } else if (nestedObjArray.length === 1) {
              let field = item[column.key];
              if (field === undefined || field === null) {
                newValue[_columnTitle] = '';
              } else {
                field = item[column.key].toString();
                if (
                  column.render === 'comboCustom' &&
                  column.comboId &&
                  combos.combosCustom[column.comboId]?.data?.length
                ) {
                  combos.combosCustom[column.comboId].data.forEach(
                    ({ description, value }) => {
                      if (value === field) newValue[_columnTitle] = description;
                    },
                  );
                } else if (
                  column.render === 'combo' &&
                  column.comboId &&
                  combos.combos[componentId]
                ) {
                  const { key, comboId } = column;

                  if (
                    combos.combos[componentId]?.[key]?.[comboId]?.data.length
                  ) {
                    combos.combos[componentId][key][comboId].data.forEach(
                      ({ description, value }) => {
                        if (value.toString() === field.toString())
                          newValue[_columnTitle] = description;
                      },
                    );
                  }
                } else if (column.render === 'check') {
                  field === 'true'
                    ? (newValue[_columnTitle] = 'Sí')
                    : (newValue[_columnTitle] = 'No');
                } else if (field && column.render === 'date') {
                  newValue[_columnTitle] = intl.formatDate(field, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  });
                } else newValue[_columnTitle] = field;
              }
            }
          }
        });
      result.push(newValue);
    });

    return [result, headers];
  };

  dataToCSV = (data: IRow[], columns: Column[]) => {
    if (isEmpty(data)) {
      return null;
    }
    //const keys = Object.keys(data[0]);
    let result = '';
    // result += keys.join(config.EXPORT.COLUMN_DELIMITER);
    // result += config.EXPORT.LINE_DELIMITER;

    const newColumns = columns.filter((column) => column.mustRender);

    newColumns.forEach((column) => {
      if (column !== newColumns[newColumns.length - 1])
        result += column.title + config.EXPORT.COLUMN_DELIMITER;
      else result += column.title + config.EXPORT.LINE_DELIMITER;
    });

    const { combos, intl, componentId } = this.props;
    let ctrl: boolean;
    data.forEach((item) => {
      ctrl = false;
      columns
        .filter((column) => column.mustRender)
        .forEach((column) => {
          if (ctrl) result += config.EXPORT.COLUMN_DELIMITER;
          let newValue = '';
          if (column && column.key && item) {
            const nestedObjArray = column.key.toString().split('.');
            if (
              nestedObjArray.length > 2 &&
              item[nestedObjArray[0]]?.[nestedObjArray[1]]?.[nestedObjArray[2]]
            ) {
              if (column.render === 'combo' && combos.combos[componentId]) {
                const { key, comboId } = column;
                if (!comboId) return;
                if (combos.combos[componentId]?.[key]?.[comboId]?.data?.length)
                  combos.combos[componentId][key][comboId].data.forEach(
                    ({ description, value }) => {
                      if (
                        value?.toString() ===
                        item[nestedObjArray[0]][nestedObjArray[1]][
                          nestedObjArray[2]
                        ]?.toString()
                      )
                        newValue = description;
                    },
                  );
              }
            } else if (
              nestedObjArray.length > 1 &&
              item[nestedObjArray[0]]?.[nestedObjArray[1]]
            ) {
              if (
                column.comboId &&
                column.render === 'comboCustom' &&
                combos.combosCustom[column.comboId]?.data
              ) {
                //Caso combo anidado
                combos.combosCustom[column.comboId].data.forEach(
                  ({ description, value }) => {
                    if (
                      value?.toString() ===
                      item[nestedObjArray[0]][nestedObjArray[1]]?.toString()
                    ) {
                      newValue = description;
                    }
                  },
                );
              } else if (
                column.render === 'combo' &&
                combos.combos[componentId]
              ) {
                const { key, comboId } = column;
                if (!comboId) return;
                if (combos.combos[componentId]?.[key]?.[comboId]?.data?.length)
                  combos.combos[componentId][key][comboId].data.forEach(
                    ({ description, value }) => {
                      if (
                        value?.toString() ===
                        item[nestedObjArray[0]][nestedObjArray[1]]?.toString()
                      )
                        newValue = description;
                    },
                  );
              } else {
                let field =
                  item[nestedObjArray[0]][nestedObjArray[1]].toString();
                newValue = field;
              }
            } else if (
              nestedObjArray.length > 1 &&
              item[nestedObjArray[0]]?.[0]
            ) {
              if (
                column.comboId &&
                column.render === 'comboCustom' &&
                combos.combosCustom[column.comboId]?.data
              ) {
                //Caso combo anidado
                combos.combosCustom[column.comboId].data.forEach(
                  ({ description, value }) => {
                    if (
                      value?.toString() ===
                      item[nestedObjArray[0]][nestedObjArray[1]]?.toString()
                    ) {
                      newValue = description;
                    }
                  },
                );
              } else if (
                column.render === 'combo' &&
                combos.combos[componentId]
              ) {
                const { key, comboId } = column;
                if (!comboId) return;
                if (combos.combos[componentId]?.[key]?.[comboId]?.data?.length)
                  combos.combos[componentId][key][comboId].data.forEach(
                    ({ description, value }) => {
                      if (
                        value?.toString() ===
                        item[nestedObjArray[0]][0]['statusResource']?.toString()
                      )
                        newValue = description;
                    },
                  );
              } else {
                let field = item[nestedObjArray[0]][0]['createDate'].toString();
                newValue = field;
              }
            } else if (nestedObjArray.length === 1) {
              let field = item[column.key];
              if (field === undefined || field === null) newValue = '';
              else {
                field = item[column.key]
                  .toString()
                  .split(config.EXPORT.COLUMN_DELIMITER);
                if (field.length > 1) field = field.join(',');
                else field = field[0];
                if (
                  column.comboId &&
                  column.render === 'comboCustom' &&
                  combos.combosCustom[column.comboId]?.data?.length
                ) {
                  //Caso combo no anidado
                  combos.combosCustom[column.comboId].data.forEach(
                    ({ description, value }) => {
                      if (value?.toString() === field?.toString())
                        newValue = description;
                    },
                  );
                } else if (
                  column.comboId &&
                  column.render === 'combo' &&
                  combos.combos[componentId]
                ) {
                  const { key, comboId } = column;
                  if (
                    combos.combos[componentId]?.[key]?.[comboId]?.data?.length
                  )
                    combos.combos[componentId][key][comboId].data.forEach(
                      ({ description, value }) => {
                        if (value?.toString() === field?.toString())
                          newValue = description;
                      },
                    );
                } else if (column.render === 'check') {
                  field === 'true' ? (newValue = 'Sí') : (newValue = 'No');
                } else if (field && column.render === 'date') {
                  newValue = intl.formatDate(field, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  });
                } else newValue = field;
              }
            }
          }
          result += newValue;
          ctrl = true;
        });
      result += config.EXPORT.LINE_DELIMITER;
    });
    return result;
  };

  processData = (
    componentId: string,
    data: IRow[],
    columns: Column[],
    format: string,
    selectedRowKeys?: number[],
    rowKey?: string,
  ) => {
    if (selectedRowKeys && rowKey) {
      data = data.filter((row) => {
        return selectedRowKeys.includes(row[rowKey]);
      });
    }

    let text: string | null = null;
    let type, blob;
    let fileName = (componentId || 'data') + '.' + format;

    if (format === dataType.CSV.format) {
      text = this.dataToCSV(data, columns);
      type = dataType.CSV.type;
      if (text !== null) blob = new Blob([text], { type: type });
    }

    if (isEmpty(text)) return;

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  getAllData = async () => {
    const { componentId } = this.props;
    const currentParams = store.getState().tables[componentId].queryParams;
    const filters = store.getState().tables[componentId].filters;

    let callParams = { ...currentParams };
    callParams.q = isEmpty(filters)
      ? callParams.q
      : isEmpty(callParams.q)
      ? filters
      : filters + config.QUERY.AND + callParams.q;
    callParams.size = 30000;
    const response = await api.getDataCall({
      dataPath: appComponents[componentId].path,
      callConfig: { params: callParams },
    });
    return response;
  };

  getAllDataPIN = async () => {
    const { componentId, totalElements } = this.props;
    const currentParams = store.getState().tables[componentId].queryParams;
    const filters = store.getState().tables[componentId].filters;
    const requestSize = 500;
    const dividedTotalelements = totalElements / requestSize;
    const promises: Promise<AxiosResponse<any>>[] = [];
    const response: { data: { content: any[] }; status: number } = {
      data: { content: [] },
      status: 0,
    };

    for (let i = 0, l = Math.ceil(dividedTotalelements); i < l; i++) {
      let callParams = { ...currentParams };
      callParams.q = isEmpty(filters)
        ? callParams.q
        : isEmpty(callParams.q)
        ? filters
        : filters + config.QUERY.AND + callParams.q;
      callParams.size = requestSize;
      callParams.page = i;
      promises.push(
        api
          .getDataCall({
            dataPath: appComponents[componentId].path,
            callConfig: { params: callParams },
          })
          .then((response) => response.data.content),
      );
    }
    const responses = await Promise.all(promises);

    response.data.content = _.flatten(responses);
    response.status = 200;

    return response;
  };

  onClick = async ({ key }: { key: string }) => {
    this.setState({ exportLoading: true });
    const response = isPINPlatform()
      ? await this.getAllDataPIN()
      : await this.getAllData();
    this.setState({ exportLoading: false });
    if (response.status === 200 && response.data) {
      const data = response.data.content;
      const { componentId, selectedRowKeys, rowKey, columns } = this.props;
      switch (key) {
        case 'AC':
          this.processData(componentId, data, columns, 'csv');
          break;
        case 'AX':
          // @ts-ignore
          let [xlsxData, headers] = this.dataToXLS(data, columns);

          let wsheet = [];
          var wbook = XLSX.utils.book_new();

          // @ts-ignore
          wsheet[0] = XLSX.utils.json_to_sheet(xlsxData, {
            header: Object.values(headers),
          });
          XLSX.utils.book_append_sheet(wbook, wsheet[0], 'valueSheet');

          XLSX.writeFile(wbook, `${componentId}.xlsx`, {
            bookType: 'xlsx',
            type: 'binary',
          });
          break;
        case 'SC':
          this.processData(
            componentId,
            data,
            columns,
            'csv',
            selectedRowKeys,
            rowKey,
          );
          break;
        case 'SX':
          this.processData(
            componentId,
            data,
            columns,
            'xls',
            selectedRowKeys,
            rowKey,
          );
          break;
        default:
          break;
      }
    } else {
      feedback({
        type: 'notification',
        method: 'info',
        message: 'No se ha podido realizar la acción',
      });
    }
  };

  onClickDownload = async ({ key }: { key: string }) => {
    const fileType = key === 'AC' ? 'csv' : 'xlsx';
    const s3file = await api.getS3SignedFileURL(fileType);

    let tempLink = document.createElement('a');
    tempLink.href = s3file.data;
    tempLink.setAttribute('download', `${this.props.componentId}.${fileType}`);
    document.body.appendChild(tempLink);
    tempLink.click();
  }

  render() {
    const { disabled, intl, componentId } = this.props;

    const menu = (
      <Menu onClick={isPINPlatform() && componentId === 'usersusersListLiww' ? this.onClickDownload:this.onClick}>
        <Menu.Item key="AC">.csv</Menu.Item>
        {isPINPlatform() && componentId === 'usersusersListLiww' ? '' : <Menu.Item key="AX">.xls</Menu.Item>}
      </Menu>
    );

    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        disabled={disabled || this.state.exportLoading}>
        <Button
          className="single-btn"
          style={{ float: 'right', marginRight: 10 }}
          icon={this.state.exportLoading ? 'loading' : 'export'}>
          {intl.formatMessage({ id: 'export.title' })}
        </Button>
      </Dropdown>
    );
  }
}

export default injectIntl(DataExport);
