import { Button, Col, Icon, Input, Row, Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import FormItem from 'antd/lib/form/FormItem';
import React, { FC } from 'react';
import {
  IPreHeaderRole,
  IPreHeaderRoleMessage,
  MessageWhiteListMatchType,
  PreHeaderRoleListKey,
  WHITE_LIST_TYPES,
} from '../../../models/preheader.models';

import usePreHeaderWhiteList from '../../../hooks/usePreHeaderWhiteList';

interface PreHeaderWhiteListProps {
  type: WHITE_LIST_TYPES;
  role: IPreHeaderRole;
  message: IPreHeaderRoleMessage;
  handleChange: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
}

const PreHeaderWhiteList: FC<PreHeaderWhiteListProps> = ({
  type,
  message,
  handleChange,
  role,
}) => {
  const {
    whiteListItems,
    whiteList,
    setWhiteList,
    errors,
    loading,
    createWhiteList,
    deleteWhiteList,
  } = usePreHeaderWhiteList({ message, type, handleChange, role });

  return (
    <>
      {whiteListItems?.map((whiteList) => (
        <Row key={whiteList.id}>
          <Col
            span={5}
            style={{
              border: '1px solid #d9d9d9',
              borderRadius: 5,
              padding: 5,
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 5,
              width: 'max-content',
            }}>
            <Text>
              {whiteList.matchType === 'contains' &&
                `Contains: ${whiteList.value}`}
              {whiteList.matchType === 'exact' &&
                `Is exactly: ${whiteList.value}`}
            </Text>
            <Icon
              onClick={(e) => deleteWhiteList(whiteList.id!)}
              style={{ fontSize: 16, color: 'red', marginLeft: 'auto' }}
              type="delete"
            />
          </Col>
        </Row>
      ))}
      <Row>
        <Col span={5}>
          <FormItem
            colon={false}
            // validateStatus={errors.matchType ? 'error' : ''}
            // help={errors.matchType}
          >
            <Select
              placeholder="Contains"
              defaultValue={whiteList.matchType}
              onChange={(e: any) =>
                setWhiteList({
                  ...whiteList,
                  matchType: e as MessageWhiteListMatchType,
                })
              }>
              <Select.Option value="contains">Contains</Select.Option>
              <Select.Option value="exact">Is exactly</Select.Option>
            </Select>
          </FormItem>
        </Col>
        <Col span={5} style={{ marginLeft: 10 }}>
          <FormItem
            colon={false}
            validateStatus={errors.value ? 'error' : ''}
            help={errors.value}>
            <Input
              placeholder={
                type === WHITE_LIST_TYPES.URL
                  ? 'Escribe la url como en el ejemplo'
                  : 'Escribe el utm como en el ejemplo'
              }
              value={whiteList.value || ''}
              onChange={(e) =>
                setWhiteList({
                  ...whiteList,
                  value: e.target.value,
                })
              }
            />
          </FormItem>

          <p
            style={{
              fontSize: 10,
              color: 'grey',
              textAlign: 'end',
            }}>
            <Icon type="info-circle" style={{ margin: 3 }} />
            {type === WHITE_LIST_TYPES.URL
              ? 'Format example of url: /shopping-bag'
              : 'Format example: utm_medium=newsletter'}
          </p>
        </Col>

        <Button
          loading={loading}
          disabled={loading}
          style={{
            marginLeft: '5px',
            height: 30,
            marginTop: 4,
          }}
          type="primary"
          onClick={createWhiteList}>
          <Icon type="plus" />
        </Button>
      </Row>
    </>
  );
};

export default PreHeaderWhiteList;
