import {
  Button,
  Checkbox,
  Col,
  Icon,
  Input,
  Modal,
  notification,
  Progress,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import Upload from 'rc-upload';
import React, { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { authTypes } from '../../auth/authTypes';
import config from '../../config';
import {
  getURLType,
  IFileDimensions,
  isImageFileFormat,
  isVideoFileFormat,
  isYoutubeFormat,
  IUploadActions,
  ResourceDetailsMap,
  S3BucketPathEnum,
  typesOfSelectEnum,
  UPLOAD_ACTION_IMAGE,
} from '../../shared';
import store from '../../store';
import {
  getPlatformBaseUrl,
  getValidFormats,
  isConPlatform,
  isPINPlatform,
  isWebeatPlatform,
  loadItem,
  PLATFORM_CODE_KEY,
  saveState,
} from '../../utils';
import { ResourceTypeDetailEnum, ResourceTypeEnum } from '../Enums';
import './UploadComponent.css';
import { validateVideoUrl, VideoTypeEnum } from './Resource.utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export type UploadFormats = 'image' | 'pdf' | 'video' | 'document';

interface OwnProps {
  children?: JSX.Element | JSX.Element[];
  disabled?: boolean;
  fileDimensions?: IFileDimensions;
  format?: UploadFormats;
  loading?: boolean;
  maxSize?: number | null;
  token: string;
  value?: any | any[];
  uploadActions?: IUploadActions[];
  handleChangeField?: any;
  fieldName?: any;
  setValue: (value?: string | null, fileType?: typesOfSelectEnum) => void;
  materialType?: typesOfSelectEnum;
  customUploadPath?: string;
  primaryEntityId?: string | number;
  module?: string;
  consumableUpload?: boolean;
  isVerticalVideo?: boolean;
  idResourceTypeD?: ResourceTypeDetailEnum;
  S3BucketPath?: S3BucketPathEnum;
  setIsVerticalVideo?: (isVerticalVideo: boolean) => void;
  showIsVerticalVideo?: boolean;
}

export default function UploadComponent({
  children,
  disabled,
  fileDimensions,
  format = 'image',
  loading,
  setValue,
  maxSize = null,
  // maxSize = SIZE_LIMIT, -- TODO define size restrictions
  token,
  value,
  showIsVerticalVideo = false,
  isVerticalVideo,
  setIsVerticalVideo,
  uploadActions = [UPLOAD_ACTION_IMAGE],
  handleChangeField,
  fieldName,
  materialType,
  customUploadPath,
  primaryEntityId,
  module,
  consumableUpload,
  idResourceTypeD,
  S3BucketPath,
}: OwnProps) {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [fileType, setFileType] = useState(
    getURLType(value, format, materialType, uploadActions),
  );
  const {
    VIDEO,
    IMAGE,
    DOCUMENT,
    TEXT: YOUTUBE_URL,
    VIDEO_URL_VIMEO,
    QUIZ: PDF_FILE,
  } = ResourceTypeEnum;
  const apiRootUrl = getPlatformBaseUrl();
  const platformCode = loadItem(PLATFORM_CODE_KEY);
  const storageRootUrl = config.BUCKET.WEBEAT_BUCKET;

  if (format === 'image') {
    switch (fileType) {
      case ResourceTypeEnum.DOCUMENT:
        format = 'pdf';
        break;
      case ResourceTypeEnum.VIDEO:
      case ResourceTypeEnum.VIDEO_URL_VIMEO:
      case ResourceTypeEnum.IMAGE:
      default:
        format = 'image';
        break;
    }
  }

  const [selectedAction, setSelectedAction] = useState<typesOfSelectEnum>(
    parseInt(fileType.toString()) || typesOfSelectEnum.IMAGE,
  );

  const [uploading, setUploading] = useState(false);

  const getFileType = () => {
    if (format === 'image' || format === 'video') {
      return 'MEDIA';
    } else if (format === 'pdf') {
      return 'PDF';
    } else if (format === 'document') {
      return 'DOC';
    } else {
      return 'DOC';
    }
  };

  const getUploadPath = () => {
    if (customUploadPath) {
      return `${apiRootUrl}${customUploadPath}`;
    } else if (isWebeatPlatform()) {
      return `${apiRootUrl}upload/${primaryEntityId}/${getFileType()}?module=${module}`;
    } else if (isConPlatform()) {
      if (consumableUpload) {
        return `${apiRootUrl}${apiPaths.UPLOAD_MEDIA}?format=${format}&consumable=true`;
      } else
        return `${apiRootUrl}${apiPaths.UPLOAD_MEDIA}?format=${format}`.replace(
          /([^:]\/)\/+/g,
          '$1',
        );
    } else if (idResourceTypeD && ResourceDetailsMap.has(idResourceTypeD)) {
      return `${apiRootUrl}/${apiPaths.UPLOAD_MEDIA}/${ResourceDetailsMap.get(
        idResourceTypeD,
      )}`.replace(/([^:]\/)\/+/g, '$1');
    } else if (S3BucketPath) {
      return `${apiRootUrl}/${apiPaths.UPLOAD_MEDIA}/${S3BucketPath}`.replace(
        /([^:]\/)\/+/g,
        '$1',
      );
    } else {
      return `${apiRootUrl}/${apiPaths.UPLOAD_MEDIA}/${S3BucketPathEnum.DEFAULT}`.replace(
        /([^:]\/)\/+/g,
        '$1',
      );
    }
  };

  const validFormats = getValidFormats(format, selectedAction, fileType);
  const hasMultipleActions = uploadActions.length > 1;

  const handleRemove = (
    file: UploadFile<any>,
  ): boolean | void | Promise<boolean | void> => {
    setValue(null, selectedAction);
  };

  const checkFileDimensions = ([height, width]: any) => {
    return height === fileDimensions?.height && width === fileDimensions?.width;
  };

  const validateFile = (file: RcFile) => {
    const { type, size, name } = file;

    switch (true) {
      case maxSize !== null && size > maxSize:
        return {
          message: formatMessage({ id: 'upload.error.select' }),
          description: formatMessage({ id: 'upload.size' }),
        };
      case type === '':
        const format =
          name.indexOf('.') >= 0 ? name.slice(name.lastIndexOf('.') + 1) : '';
        if (!validFormats.includes(format))
          return {
            message: formatMessage({ id: 'upload.error.select' }),
            description: formatMessage({ id: 'upload.format' }),
          };
        break;
      case !validFormats.includes(type):
        return {
          message: formatMessage({ id: 'upload.error.select' }),
          description: formatMessage({ id: 'upload.format' }),
        };
    }

    return false;
  };

  const handleCheckFile = (file: RcFile, options: any, other: any) => {
    const error = validateFile(file);
    if (error)
      notification.error({
        message: error.message || formatMessage({ id: 'upload.error.default' }),
        description: error.description,
      });
    return error ? false : true;
  };

  async function getMeta(url: string) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve([img.height, img.width]);
      img.onerror = reject;
      img.src = url;
    });
  }

  const handleSuccess = async (response: {
    [key: string]: string;
  }): Promise<void> => {
    setUploading(false);
    let control = true;

    if (!isConPlatform() && fileDimensions) {
      const imageDimensions: any = await getMeta(response.url);
      if (!checkFileDimensions(imageDimensions)) control = false;
      if (!control) {
        notification.error({
          message: formatMessage({ id: 'upload.error.dimensions' }),
          description: formatMessage(
            { id: 'upload.error.dimensions-expected' },
            {
              currentHeight: imageDimensions[0],
              currentWidth: imageDimensions[1],
              expectedHeight: fileDimensions.height,
              expectedWidth: fileDimensions.width,
            },
          ),
        });
        return;
      }
    }

    let _message = '';
    switch (fileType) {
      case PDF_FILE:
        _message = 'upload.file.success';
        break;
      case VIDEO:
        _message = 'upload.video.success';
        break;
      case IMAGE:
        _message = 'upload.image.success';
        break;
      case DOCUMENT:
        _message = 'upload.document.success';
    }

    setValue(response.url ? response.url.trim() : null, selectedAction);

    notification.success({
      message: formatMessage({ id: _message }),
      duration: 3,
    });
  };

  const setNewType = (newFileType: typesOfSelectEnum) => {
    setSelectedAction(newFileType);
    switch (newFileType) {
      case typesOfSelectEnum.YOUTUBE_URL:
        setFileType(YOUTUBE_URL);
        break;
      case typesOfSelectEnum.VIDEO_URL_VIMEO:
        setFileType(VIDEO_URL_VIMEO);
        break;
      case typesOfSelectEnum.VIDEO:
        setFileType(VIDEO);
        break;
      case typesOfSelectEnum.IMAGE:
        setFileType(IMAGE);
        break;
      case typesOfSelectEnum.FILE:
      case typesOfSelectEnum.DOCUMENT:
      default:
        setFileType(DOCUMENT);
        break;
    }
    setValue(null, newFileType);
  };

  const handleFileTypeChange = (_fileType: typesOfSelectEnum) => {
    if (Boolean(value)) {
      Modal.confirm({
        title: formatMessage({ id: 'upload.image.modalTitle' }),
        okText: formatMessage({ id: 'pop.accept' }),
        cancelText: formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          setNewType(_fileType);
        },
        onCancel() {
          return;
        },
      });
    } else {
      setNewType(_fileType);
    }
  };

  const typeSelector = hasMultipleActions && (
    <Col span={6}>
      <Select
        disabled={disabled}
        defaultValue={selectedAction}
        value={selectedAction}
        className="fileTypeSelect"
        dropdownClassName="fileTypeSelect--options"
        onChange={handleFileTypeChange}
        id={fieldName}>
        {uploadActions.map(({ icon, id, label }) => {
          return (
            <Select.Option value={id} className="fileTypeSelect" key={id}>
              <Icon type={icon} />
              {label && (
                <span>
                  {formatMessage({
                    id: label,
                  })}
                </span>
              )}
            </Select.Option>
          );
        })}
      </Select>
    </Col>
  );

  const seePreview = Boolean(value?.trim())
    ? fileType === IMAGE
      ? isImageFileFormat(value)
      : fileType === VIDEO
      ? isVideoFileFormat(value)
      : fileType === YOUTUBE_URL
      ? isYoutubeFormat(value)
      : false
    : false;

  const seeRemove =
    Boolean(value) &&
    [typesOfSelectEnum.IMAGE, typesOfSelectEnum.VIDEO].includes(selectedAction);

  const isLittleComponent = [
    'coverVideo',
    'coverVideoMobile',
    'coverImage',
    'endImage',
    'coverImageMobile',
    'endImageMobile',
  ].includes(fieldName);

  function handleChangeVideoInput(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.trim();
    const youtubeId = validateVideoUrl(value, VideoTypeEnum.YOUTUBE);
    setValue(youtubeId.value, selectedAction);
  }

  function handleChangeVimeoInput(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.trim();
    const vimeoId = validateVideoUrl(value, VideoTypeEnum.VIMEO);
    setValue(vimeoId.value, selectedAction);
  }

  const urlComponent = format !== 'pdf' && YOUTUBE_URL === fileType && (
    <>
      <Col className={fieldName}>
        <Tooltip
          className="fieldTooltip"
          title={formatMessage({ id: 'upload.video.youtubeID.tooltip' })}>
          <Icon type="question-circle-o" />
        </Tooltip>
      </Col>
      <Col
        className={fieldName}
        span={
          seePreview
            ? hasMultipleActions
              ? isLittleComponent
                ? 9
                : 10
              : 12
            : 14
        }>
        <Input
          disabled={disabled}
          onChange={(e) => {
            handleChangeVideoInput(e);
          }}
          allowClear
          value={value}
          defaultValue={
            Boolean(value) &&
            getURLType(value, format, materialType) === YOUTUBE_URL
              ? value
              : ''
          }
        />
      </Col>
    </>
  );

  const vimeoUrlComponent = format !== 'pdf' &&
    VIDEO_URL_VIMEO === fileType && (
      <>
        <Col className={fieldName}>
          <Tooltip
            className="fieldTooltip"
            title={formatMessage({ id: 'upload.video.vimeoID.tooltip' })}>
            <Icon type="question-circle-o" />
          </Tooltip>
        </Col>
        <Col
          className={fieldName}
          span={
            seePreview
              ? hasMultipleActions
                ? isLittleComponent
                  ? 9
                  : 10
                : 12
              : 14
          }>
          <Input
            disabled={disabled}
            onChange={(e) => {
              handleChangeVimeoInput(e);
            }}
            value={value}
            allowClear
            defaultValue={
              Boolean(value) &&
              getURLType(value, format, materialType) === VIDEO_URL_VIMEO
                ? value
                : ''
            }
          />
        </Col>
      </>
    );

  const handleError = (err: any, response: any, file: any) => {
    if (response.code === 401) {
      api
        .refreshCall()
        .then((response: any) => {
          const { accessToken, refreshToken } = response.data;
          const auth = {
            accessToken: `Bearer ${accessToken}`,
            refreshToken: refreshToken,
            isAuthorized: true,
          };
          saveState({ auth });
          store.dispatch({
            type: authTypes.SET_TOKENS,
            payload: {
              accessToken: `Bearer ${accessToken}`,
              refreshToken: refreshToken,
            },
          });
          notification.warning({
            message: formatMessage({ id: 'upload.image.session-refreshed' }),
          });
        })
        .catch((_err) => {
          notification.warning({
            message: formatMessage({ id: 'server.session.expired' }),
          });
          api.forceLogout();
        })
        .finally(() => {
          setUploading(false);
        });
    } else {
      notification.error({
        message: formatMessage({ id: 'upload.error.unknow' }),
        description: response && response.message ? response.message : '',
      });
      setUploading(false);
    }
  };

  const handleRemoveImage = (data: any) => {
    Modal.confirm({
      title: formatMessage({ id: 'upload.image.modalTitle' }),
      okText: formatMessage({ id: 'pop.accept' }),
      cancelText: formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      async onOk() {
        setValue(null, selectedAction);
      },
      onCancel() {},
    });
  };

  let uploadComponentSpan = 24;

  if (hasMultipleActions) {
    uploadComponentSpan = seePreview ? (seeRemove ? 12 : 13) : 18;
  } else if (seePreview) {
    uploadComponentSpan = seeRemove ? 12 : 14;
  }

  const uploadInput = [VIDEO, IMAGE, DOCUMENT, typesOfSelectEnum.FILE].includes(
    fileType,
  ) && (
    <Col span={uploadComponentSpan}>
      <Upload
        loading={loading ?? false}
        name="upload"
        disabled={disabled ?? false}
        action={getUploadPath()}
        headers={{
          Authorization: token,
          platform: config.APP.PLATFORM,
          'x-isdin-platform': platformCode || config.APP.PLATFORM_CODE,
        }}
        beforeUpload={handleCheckFile}
        onError={handleError}
        onStart={() => setUploading(true)}
        onSuccess={handleSuccess}
        handleRemove={handleRemove}
        fileList={value ? [{ id: value }] : []}>
        {children}
      </Upload>
    </Col>
  );

  const previewComponent = seePreview && (
    <Col
      span={isLittleComponent ? 5 : hasMultipleActions ? 4 : 8}
      style={{ paddingLeft: '0px', textAlign: 'center' }}>
      <Button
        className="uploadButton uploadButton__margin-top"
        onClick={() => setShowModal(true)}
        disabled={disabled ?? false}>
        <Icon type="eye" />
        {!isLittleComponent &&
          formatMessage({
            id: 'challenge.media.preview',
          })}
      </Button>
    </Col>
  );

  const removeComponent = seeRemove && (
    <Col
      span={isLittleComponent ? 5 : 2}
      style={{ paddingLeft: '0px', textAlign: 'center' }}>
      <Button
        className="removeButton"
        onClick={handleRemoveImage}
        disabled={disabled ?? false}
        type="danger">
        <Icon type="delete" />
      </Button>
    </Col>
  );

  return (
    <>
      {format === 'video' && showIsVerticalVideo ? (
        <div>
          <Checkbox
            checked={isVerticalVideo || false}
            disabled={disabled}
            onChange={(e: CheckboxChangeEvent) => {
              if (setIsVerticalVideo) setIsVerticalVideo(e.target.checked);
            }}>
            <span className="form__checkLabel">
              {formatMessage({
                id: 'tree-resource.event_quiz_typeform_event_video_vertical',
              })}
            </span>
          </Checkbox>
        </div>
      ) : null}

      <Row type="flex" align="middle" gutter={[12, 0]}>
        {typeSelector}
        {uploadInput}
        {urlComponent}
        {vimeoUrlComponent}
        {previewComponent}
        {removeComponent}

        <Modal
          visible={showModal}
          centered
          onCancel={() => setShowModal(false)}
          footer={false}
          className="previewModal"
          destroyOnClose>
          <Row
            className={`uploadButton__preview ${
              fileType === YOUTUBE_URL
                ? 'uploadButton__preview--youtube-video'
                : ''
            }`}>
            {fileType === IMAGE && (
              <img
                width="854"
                height="480"
                loading="lazy"
                style={{ objectFit: 'contain' }}
                //Workaround to show consumable images despite the webeat platform
                src={
                  !value?.startsWith('http')
                    ? `${storageRootUrl}${value}`
                    : value
                }
                alt={
                  !value?.startsWith('http')
                    ? `${storageRootUrl}${value}`
                    : value
                }
              />
            )}
            {fileType === VIDEO && (
              <video width="95%" height="auto" controls>
                <source src={value} type="video/mp4" />
              </video>
            )}
            {fileType === YOUTUBE_URL && (
              <iframe
                title="youtube_url"
                width="95%"
                height="auto"
                src={
                  isPINPlatform()
                    ? `https://www.youtube.com/embed/${value}`
                    : value
                }
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </Row>
        </Modal>
        <Modal
          visible={uploading}
          closable={false}
          footer={false}
          className="uploadingModal">
          <Row type="flex" align="middle" className="uploadingModal__row">
            <Row className="uploadingModal__container">
              <div className="uploadingModal__title">Uploading file..</div>
              <Progress percent={100} status="active" />
            </Row>
          </Row>
        </Modal>
      </Row>
    </>
  );
}
