export enum ConsumableNatureEnum {
  PROMO = 3,
  POST = 4,
  PRODUCT = 8,
  PRODUCT_SAP = 9,
  DI = 10,
  DI_SAP = 11,
  MINI = 12,
  MINI_SAP = 13,
}
